import carousel_1 from '../assets/carousel_1.JPG';
import carousel_2 from '../assets/carousel_2.JPG';
import carousel_3 from '../assets/carousel_3.JPG';
import carousel_4 from '../assets/carousel_4.JPG';
import carousel_5 from '../assets/carousel_5.JPG';
import carousel_6 from '../assets/carousel_6.JPG';
import carousel_7 from '../assets/carousel_7.JPG';
import carousel_8 from '../assets/carousel_8.JPG';
import carousel_9 from '../assets/carousel_9.JPG';
import carousel_10 from '../assets/carousel_10.JPG';
import carousel_11 from '../assets/carousel_11.JPG';
import carousel_12 from '../assets/carousel_12.JPG';
import carousel_13 from '../assets/carousel_13.JPG';
import carousel_14 from '../assets/carousel_14.JPG';
// import carousel_15 from '../assets/carousel_15.JPG';
import carousel_16 from '../assets/carousel_16.JPG';
import carousel_17 from '../assets/carousel_17.JPG';
const images = [
  {
    id: 1,
    src: carousel_1,
    alt: 'Image 1',
  },
  {
    id: 2,
    src: carousel_2,
    alt: 'Image 2 ',
  },
  {
    id: 3,
    src: carousel_3,
    alt: 'Image 3',
  },
  {
    id: 4,
    src: carousel_4,
    alt: 'Image 4',
  },
  {
    id: 5,
    src: carousel_5,
    alt: 'Image 5',
  },
  {
    id: 6,
    src: carousel_6,
    alt: 'Image 6',
  },

  {
    id: 7,
    src: carousel_7,
    alt: 'Image 7',
  },

  {
    id: 8,
    src: carousel_8,
    alt: 'Image 8',
  },

  {
    id: 9,
    src: carousel_9,
    alt: 'Image 9',
  },
  {
    id: 10,
    src: carousel_10,
    alt: 'Image 11',
  },
  {
    id: 11,
    src: carousel_11,
    alt: 'Image 11',
  },
  {
    id: 12,
    src: carousel_12,
    alt: 'Image 12',
  },
  {
    id: 13,
    src: carousel_13,
    alt: 'Image 13',
  },
  {
    id: 14,
    src: carousel_14,
    alt: 'Image 14',
  },
  // {
  //   id: 15,
  //   src: carousel_15,
  //   alt: 'Image 15',
  // },
  {
    id: 16,
    src: carousel_16,
    alt: 'Image 16',
  },
  {
    id: 17,
    src: carousel_17,
    alt: 'Image 17',
  },
];
export default images;
